<template>
  <div>
    <div class="row maindashboard">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- (금일) 진행중인 업무 -->
              {{$label('LBL0000940')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCd1"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCd1"
                  @datachange="getWorkProgress" />
              </div>
            </div>
            <div class="card-body" style="height: 213px">
              <div class="row">
                <div v-for="(progress, idx) in workProgressList" 
                  :key="idx"
                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <q-btn dense :icon="iconItems[idx]" align="left" :color="colorItems2[idx]" outline  unelevated :label="progress.workName" class="btn-fixed-width-height">
                    <q-badge color="red" floating transparent>
                      {{ progress.workCount }}
                    </q-badge>
                  </q-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 no-padding">
          <div class="cardcontents">
            <div class="card-header">
              <!-- 개선 진행 목록 -->
              {{$label('LBL0000941')}}
              <div class="card-more main-header-input">
                <c-plant
                  type="search"
                  name="plantCd3"
                  :isFirstValue="false"
                  label=""
                  v-model="plantCd3"
                  @datachange="getImprProgress"/>
              </div>
            </div>
            <div class="card-body nopadgrid">
              <c-table
                ref="grid2"
                tableId="grid2"
                :isTitle="false"
                :isDashboard="true"
                :columnSetting="false"
                :usePaging="false"
                :filtering="false"
                :isFullScreen="false"
                :hideBottom="true"
                gridHeight="217px"
                :columns="grid2.columns"
                :data="grid2.data"
                @linkClick="linkClickImp"
              >
              </c-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents directorMainLayer">
          <div class="card-header non-label-icon mapheader">
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==1"
              :color="mapObj.activeTask===1 ? 'green-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===1 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 1">
              <!-- 작업허가서 -->
              {{$label('LBL0000214')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable
              :outline="mapObj.activeTask!==2"
              :color="mapObj.activeTask===2 ? 'amber-7' : 'gray-4'" 
              :text-color="mapObj.activeTask===2 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 2">
              <!-- 위험성평가 -->
              {{$label('LBL0000334')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==3"
              :color="mapObj.activeTask===3 ? 'light-blue-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===3 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 3">
              <!-- MOC -->
              {{$label('LBL0000335')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==4"
              :color="mapObj.activeTask===4 ? 'deep-orange-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===4 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 4">
              <!-- 사고 -->
              {{$label('LBL0000336')}}
            </q-chip>
            <q-chip class="chip-task"
              square clickable 
              :outline="mapObj.activeTask!==5"
              :color="mapObj.activeTask===5 ? 'deep-purple-6' : 'gray-4'" 
              :text-color="mapObj.activeTask===5 ? 'white' : 'white'" 
              icon="directions"
              @click="() => mapObj.activeTask = 5">
              <!-- 설비점검 -->
              {{$label('LBL0000332')}}
            </q-chip>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                v-show="mapObj.activeTask===1"
                :range="true"
                :appendToBody="false"
                name="workPermitPeriod"
                v-model="mapObj.taskParam.workPermitPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===2"
                :range="true"
                :appendToBody="false"
                name="assessRiskPeriod"
                v-model="mapObj.taskParam.assessRiskPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===3"
                :range="true"
                :appendToBody="false"
                name="mocPeriod"
                v-model="mapObj.taskParam.mocPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===4"
                :range="true"
                :appendToBody="false"
                name="accidentPeriod"
                v-model="mapObj.taskParam.accidentPeriod"
                @datachange="getMapData"
              />
              <c-datepicker
                v-show="mapObj.activeTask===5"
                :range="true"
                :appendToBody="false"
                name="equipCheckPeriod"
                v-model="mapObj.taskParam.equipCheckPeriod"
                @datachange="getMapData"
              />
            </div>
            <!-- 시작 -->
            <!-- 정지 -->
            <q-btn 
              :outline="!mapObj.interval.isPause" 
              :color="mapObj.interval.isPause ? 'green-6' : 'red'" 
              :text-color="mapObj.interval.isPause ? 'white' : 'red'" 
              size="9px" class="mainmapstart"
              :label="mapObj.interval.isPause ? $comm.getLangLabel('LBL0000338') : $comm.getLangLabel('LBL0000339')"
              :icon="mapObj.interval.isPause ? 'restart_alt' : 'pause'"
              @click="pause">
              <!-- 업무영역 Rotate -->
              <q-tooltip>{{$label('LBL0000337')}}</q-tooltip>
            </q-btn>
          </div>
          <div class="card-body" ref="carousel" style="padding: 15px !important;">
            <q-carousel
              v-if="mapObj.maps && mapObj.maps.length > 0"
              class="workPermit-carousel"
              swipeable
              animated
              arrows
              control-type="regular"
              control-color="grey-6"
              v-model="mapObj.slide"
              infinite
            >
              <q-carousel-slide 
                v-for="(map, idx) in mapObj.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc">
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <!-- <div v-if="showMarkArea"
                    :style="{'height': imgHeight(map) }"> -->
                    <!-- 작업허가서 -->
                    <template v-if="mapObj.activeTask===1">
                      <VueDraggableResizable
                        v-for="(item, idx) in map.maps"
                        :key="idx"
                        ref="markImage"
                        class="mainMarkImage"
                        :resizable="false"
                        :parent="true"
                        :draggable="false"
                        :x="item.locationXcoordinate * mapRate"
                        :y="item.locationYcoordinate * mapRate"
                        :w="40 " :h="40"
                        :grid="[20, 20]"
                      >
                        <q-icon name="push_pin" class="blinking" />
                        <!-- <div class="markImage-abbreviation-parent"
                          :style="{ 
                            width: !item.isHover 
                              ? item.resultTypes.length > 3 ? '227px' : getWidth(item)
                              : '241px', 
                            left: getLeft(item),
                            top: getTop(item),
                          }"> -->
                        <div class="markImage-abbreviation-parent"
                          :style="{ 
                            width: '341px',
                            left: getLeft(item),
                            top: getTop(item),
                          }">
                          <!-- <div class="markImage-abbreviation" 
                            @mouseover="() => { item.isHover = true }"
                            @mouseleave="() => { item.isHover = false }"> -->
                          <div class="markImage-abbreviation">
                            <!-- <transition name="mark-list" tag="div">
                              <div v-if="!item.isHover" class="markImage-tran">
                                <q-banner v-for="(resultType, idx) in item.resultTypes.slice(0, 3)"
                                  :key="idx"
                                  dense 
                                  :class="[
                                    'markImage-abbreviation-banner',
                                    item.resultTypes.length < 4 && idx === item.resultTypes.length - 1 ? 'last-banner' : ''
                                  ]">
                                  <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                  <div>
                                    {{resultType.sopWorkTypeName}}
                                  </div>
                                </q-banner>
                                <q-banner v-if="item.resultTypes.length > 3"
                                  key="-1"
                                  dense 
                                  :class="['markImage-abbreviation-plus']">
                                  <q-icon name="add" class="text-red" />{{item.resultTypes.length - 3}}
                                  <div>
                                    <q-icon name="more_horiz" class="text-black" />
                                  </div>
                                </q-banner>
                              </div>
                            </transition> -->
                            <transition name="mark-list" tag="div">
                              <!-- <div v-show="item.isHover"> -->
                              <div v-show="true">
                                <q-banner v-for="(resultType, idx) in item.resultTypes"
                                  :key="idx"
                                  dense 
                                  :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                  <template v-slot:avatar>
                                    <q-icon :name="workPermitIconName(resultType)" class="text-red" />
                                  </template>
                                  {{resultType.sopWorkTypeName}}
                                  <q-badge :color="workPermitbadgeColor(resultType.swpStepName)" :label="resultType.swpStepName" />
                                  <q-btn class="q-ml-sm" outline round dense color="red" size="9px" label="" icon="add" @click="openWorkPermit(resultType)" />
                                  <div class="caption-work">
                                    {{resultType.workSummary}}
                                  </div>
                                </q-banner>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </VueDraggableResizable>
                    </template>
                    <!-- 작업허가서를 제외한 나머지 업무 -->
                      <!-- class-name-active="my-active-class" -->
                    <VueDraggableResizable
                      v-for="(mp, idx) in map.otherMaps"
                      :key="'other' + idx"
                      :ref="'processMark' + mp.processCd"
                      class="my-class"
                      :class="mp.processCd === mapObj.process.processCd ? 
                        'my-active-class' : ''
                      "
                      style="z-index:1"
                      :parent="true"
                      :draggable="false"
                      :resizable="false"
                      :x="mp.x * mapRate"
                      :y="mp.y * mapRate"
                      :w="mp.w * mapRate" :h="mp.h * mapRate"
                      :grid="[20, 20]"
                      @activated="onClickProcess(mp)"
                    >
                      <div>{{mp.processName}}</div>
                    </VueDraggableResizable>
                    <div
                      v-for="(mp, idx) in map.otherMaps.filter(i => {
                        return i.tasks.filter(i => {
                          return i.taskType === String(mapObj.activeTask)
                        }).length > 0
                      })"
                      :key="'other-tran' + idx"
                      class="task-parent"
                      :style="setStyleTaskArea(mp)"
                      @mouseover="() => { mp.isHover = true }"
                      @mouseleave="() => { mp.isHover = false }">
                      <div v-show="mapObj.activeTask===2 
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #ffb300">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="amber-7" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 평가진행 건 수 -->
                            {{$label('LBL0000340')}}: <font class="text-amber-7">{{task.actionCnt}}</font><br/>
                            <!-- 위험등록부 건 수 -->
                            {{$label('LBL0000341')}}: <font class="text-amber-7">{{task.riskRegisterCnt}}</font><br/>
                            <!-- 미조치 Punch-list 건 수 -->
                            {{$label('LBL0000342')}}: <font class="text-amber-7">{{task.notActionImprCnt}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===3 
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #03a9f4">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="light-blue-6" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- PSI진행 건 수 -->
                            {{$label('LBL0000343')}}: <font class="text-blue">{{task.psiCnt}}</font><br/>
                            <!-- 위험성평가실시 진행 건 수 -->
                            {{$label('LBL0000344')}}: <font class="text-light-blue-6">{{task.assessCnt}}</font><br/>
                            <!-- 가동전 점검(진행중/완료) -->
                            {{$label('LBL0000345')}}: <font class="text-light-blue-6">{{task.preStartCnt}}</font>
                            <!-- Punch list(진행중/완료) -->
                            {{$label('LBL0000346')}}: <font class="text-light-blue-6">{{task.punchCnt}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===4
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #ff5722">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="deep-orange-6" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 사고종류 -->
                            {{$label('LBL0000347')}}: <font class="text-deep-orange-6">{{task.accidentKindName}}</font><br/>
                            <!-- 사고등급 -->
                            {{$label('LBL0000348')}}: <font class="text-deep-orange-6">{{task.accidentGradeName}}</font><br/>
                            <!-- 사고원인(대/중분류) -->
                            {{$label('LBL0000349')}}: <font class="text-deep-orange-6">{{task.occurrenceModeName}}</font>
                          </div>
                        </q-banner>
                      </div>
                      <div v-show="mapObj.activeTask===5
                        && mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length > 0" class="rounded-borders task-detail"
                          style="border-top:3px solid #673ab7">
                        <q-banner v-for="(task, _idx) in mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).slice(0, 1)"
                          :key="_idx"
                          dense>
                          <div class="mainmapbannerTitle">{{task.name}}</div>
                          <!-- <q-badge color="deep-purple-4" floating>{{mp.tasks.filter(i => {
                            return i.taskType === String(mapObj.activeTask)
                          }).length}}</q-badge> -->
                          <div class="caption-work2" style="text-align: left;">
                            <!-- 점검유형 -->
                            {{$label('LBL0000350')}}: <font class="text-deep-purple-4">{{task.equipmentCheckKindName}}</font><br/>
                            <!-- 점검일 -->
                            {{$label('LBL0000351')}}: <font class="text-deep-purple-4">{{task.checkDate}}</font><br/>
                            <!-- 점검자 -->
                            {{$label('LBL0000352')}}: <font class="text-deep-purple-4">{{task.checkUserName}}</font>
                          </div>
                        </q-banner>
                      </div>
                    </div>
                  <!-- </div> -->
                </template>
              </q-carousel-slide>
            </q-carousel>
            <!-- 등록된 지도가 없습니다. -->
            <el-empty v-else 
              :image-size="435"
              :description="$comm.getLangMessage('MSG0000137')"></el-empty>
          </div>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="card cardcontents">
          <div class="card-header">
            (최근5년) 사고현황
          </div>
          <div class="card-body">
            <apexchart 
              ref="yearOcuurChart" 
              height="202px" 
              type="line"
              :options="yearOcuurChart.chartOptions" 
              :series="yearOcuurChart.series"></apexchart>
          </div>
        </div>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 아차사고 현황 -->
            {{$label('LBL0000942')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                name="plantCdAcc"
                :isFirstValue="false"
                label=""
                v-model="plantCdAcc"
                @datachange="getNearmiss"/>
            </div>
          </div>
          <div class="card-body">
            <c-table
              ref="gridAcc"
              tableId="gridAcc"
              :isTitle="false"
              :isDashboard="true"
              :columnSetting="false"
              :usePaging="false"
              :filtering="false"
              :isFullScreen="false"
              :hideBottom="true"
              gridHeight="310px"
              :columns="gridAcc.columns"
              :data="gridAcc.data"
              @linkClick="linkClickAcc"
            >
            </c-table>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <div class="cardcontents">
          <div class="card-header">
            <!-- 통합개선 이행율 현황 -->
            {{$label('LBL0000943')}}
            <div class="card-more main-header-input">
              <c-plant
                type="search"
                label=""
                :isFirstValue="false"
                name="plantCd4"
                v-model="plantCd4"
                @datachange="datachange4" />
            </div>
            <div class="card-more main-header-input main-header-input2">
              <c-datepicker
                type="year"
                default="today"
                name="actionCompleteRequestDate"
                @input="getImprStatus"
                v-model="actionCompleteRequestDate"
              />
            </div>
          </div>
          <div class="card-body">
            <apexchart 
              ref="imprChart" 
              height="300px" 
              type="bar"
              @dataPointSelection="selectedBarImp"
              :width="imprChart.chartWidth"
              :options="imprChart.chartOptions" 
              :series="imprChart.series"></apexchart>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-notice-popup></c-notice-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import selectConfig from '@/js/selectConfig';
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  name: 'main-dashboard-director',

  components: { 
    VueDraggableResizable,
    apexchart: VueApexCharts
  },
  props: {
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      plantCd1: null,
      plantCd2: null,
      plantCd3: null,
      plantCd4: null,
      plantCdAcc: null,
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          title: {
            text: '',
            align: 'left',
            style: {
              fontSize: "14px",
              color: '#1ab394'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            width: [3,3,3,3,3,3,3,3],
            curve: 'straight',
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '100%',
      },
      imprChart: {
        chartOptions: {
          annotations: { 
            xaxis: [],
          },
          title: {
            // text: '업무별 개선 현황'
          },
          chart: {
            type: 'bar',
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            show: true,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          },
          colors: [
            '#FFC107', '#00BCD4', '#F44336',
          ],
          fill: {
            opacity: 1,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 10
          },
        },
        series: [
          {
            id: 'actioning',
            name: '조치중',
            data: [],
          },
          {
            id: 'complete',
            name: '개선완료(총합)',
            data: [],
          },
          {
            id: 'overAction',
            name: '지연 건수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      // imprChart2: {
      //   chartOptions: {
      //     annotations: { 
      //       xaxis: [],
      //     },
      //     title: {
      //       text: ''
      //       // text: '업무별 개선 현황'
      //     },
      //     chart: {
      //       type: 'bar',
      //       stacked: true,
      //     },
      //     plotOptions: {
      //       bar: {
      //         horizontal: false,
      //         columnWidth: '55%',
      //         endingShape: 'rounded'
      //       },
      //     },
      //     grid: {
      //       borderColor: '#e7e7e7',
      //       row: {
      //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      //         opacity: 0.5
      //       },
      //     },
      //     dataLabels: {
      //       enabled: true,
      //     },
      //     stroke: {
      //       show: true,
      //     },
      //     xaxis: {
      //       categories: [],
      //     },
      //     yaxis: {
      //       title: {
      //         text: undefined
      //       },
      //     },
      //     tooltip: {
      //       y: {
      //         formatter: function (val) {
      //           return val + " 건"
      //         }
      //       }
      //     },
      //     colors: [
      //       '#FFC107', '#00BCD4', '#F44336',
      //     ],
      //     fill: {
      //       opacity: 1,
      //     },
      //     legend: {
      //       position: 'top',
      //       horizontalAlign: 'left',
      //       offsetX: 10
      //     },
      //   },
      //   series: [
      //     {
      //       id: 'actioning',
      //       name: '조치중',
      //       data: [],
      //     },
      //     {
      //       id: 'complete',
      //       name: '완료',
      //       data: [],
      //     },
      //     {
      //       id: 'overAction',
      //       name: '지연',
      //       data: [],
      //     },
      //   ],
      //   chartWidth: '100%',
      // },
      rate: [],
      safRate: [],
      envRate: [],
      yearUrl: '',
      accident: {
        startYmd: '',
        endYmd: '',
      },
      grid1: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            // 작업개요
            label: 'LBL0000211',
            align: 'left',
            sortable: false,
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            // 허가일
            label: 'LBL0000213',
            align: 'center',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            // 허가서구분
            label: 'LBL0000204',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            ellipsis: true,
            sortable: false,
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          // {
          //   name: 'actionCompleteRequestDate',
          //   field: 'actionCompleteRequestDate',
          //   label: '조치완료 요청일',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
        data: [],
      },
      gridAcc: {
        listUrl: '',
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            // 아차사고명
            label: "LBL0000949",
            align: "left",
            sortable: true,
            ellipsis: true,
            type: "link",
          },
          // {
          //   name: "processName",
          //   field: "processName",
          //   label: "LBLPROCESS",
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:90px',
            // 진행단계
            label: "LBLPROGRESS",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:110px',
            // 유형(LBLTYPE) -> 아차사고종류
            label: '아차사고종류',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      mapObj: {
        activeTask: 1,
        envUrl: '',
        processUrl: '',
        url: '',
        interval: {
          obj: null,
          seconds: 3,
          isPause: false,
        },
        taskParam: {
          workPermitPeriod: [],
          assessRiskPeriod: [],
          mocPeriod: [],
          accidentPeriod: [],
          equipCheckPeriod: [],
        },
        process: {
          processCd: '',
          processName: '',
          depts: [],
          chemprods: [],
          equips: [],
          hazardChems: [],
        },
        taskDetail: {
          assessRisks: [],
          mocs: [],
          accidents: [],
          equipChecks: [],
        },
        maps: [],
        slide: 0,
        date: '',
      },
      rateUrl: '',
      patrolUrl: '',
      workPermitUrl: '',
      imprProgressUrl: '',
      workProgressUrl: '',
      actionCompleteRequestDates: [],
      workProgressList: [],
      showTooltopCnt: 0,
      showTooltop: [false,false,false,false,false,false,false,false],
      iconItems: ['school','settings_applications','build','build_circle','restore','manage_accounts','report','report_problem'],
      colorItems2: ['primary','secondary','amber-9','deep-orange','secondary','brown-5','purple','green-7',],
      ibmTaskTypeCdList: [],
      actionCompleteRequestDate: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    showMarkArea() {
      return this.setComplete &&
        ((this.mapObj.otherMaps && this.mapObj.otherMaps.length > 0)  
          || (this.mapObj.maps && this.mapObj.maps.length > 0))
    },
    mapRate() {
      return 0.835
    },
    setTaskDetailStyle() {
      let _style = {
        width: 0,
        position: 'absolute',
        left: 0,
        top: '35px',
        height: String(575 * this.mapRate + 20) + 'px',
      };
      if (this.isTaskDetailShow) {
        _style = {
          width: '50%',
          // transform: 'translate(100%, 0)',
          position: 'absolute',
          left: 0,
          top: '35px',
          height: String(575 * this.mapRate + 20) + 'px',
        }
      }
      return _style;
    },
    colType1() {
      return !this.isTaskDetailShow ? 'col-3' : 'col-2'
    },
    colType2() {
      return !this.isTaskDetailShow ? 'col-9' : 'col-10'
    },
    colType3() {
      return this.isTaskDetailShow ? 'col-6' : 'col-12'
    },
    colorItems() {
      return [
        // 계획수립
        { stepperMstCd: 'MCSC000005', stepperMstNm: this.$comm.getLangLabel('LBL0000381'), colorClass: 'blue' },
        // 점검중
        { stepperMstCd: 'MCSC000010', stepperMstNm: this.$comm.getLangLabel('LBL0000382'), colorClass: 'orange' },
        // 점검완료
        { stepperMstCd: 'MCSC000015', stepperMstNm: this.$comm.getLangLabel('LBL0000383'), colorClass: 'green' },
        // 지연
        { stepperMstCd: 'MCSC000020', stepperMstNm: this.$comm.getLangLabel('LBL0000384'), colorClass: 'red' },
      ]
    },
  },
  methods: {
    init() {
      this.showTooltopCnt = 0
      this.yearUrl = selectConfig.sop.iim.accident.status.year.url;
      this.rateUrl = selectConfig.main.imprChart.url;
      this.patrolUrl = selectConfig.main.patrolChart.url;
      this.workPermitUrl = selectConfig.main.workPermitStatus.url;
      this.imprProgressUrl = selectConfig.main.imprProgress.url;
      this.workProgressUrl = selectConfig.main.workProgress.url;
      // 지도
      this.mapObj.url = selectConfig.main.map.url
      this.mapObj.processUrl = selectConfig.main.mapProcess.url
      this.mapObj.envUrl = selectConfig.main.env.url
      // 지도

      // this.actionCompleteRequestDate = this.$comm.getThisYear();
      this.actionCompleteRequestDate = null

      // 년도 설정
      let years = [];
      this.setLangLabel();
      for (let i = 0; i < 5; i++) {
        years.splice(0, 0, this.$comm.getPrevYear(i));
      }
      this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.accident.startYmd = years[0];
      this.accident.endYmd = years[4];

      this.getYearList();
      this.getImprStatus(null);
      // this.getSafPatrols();
      this.getWorkPermits();
      this.getImprProgress();
      this.getWorkProgress();
      
      this.getMapPriodParam();
      this.getMapData();
      this.intervalActionMap();
      this.getNearmiss();

      // this.intervalTooltip1();
    },
    setLangLabel() {
      let thisVue = this;
      this.imprChart.series = [
      {
          id: 'actioning',
          name: this.$comm.getLangLabel('LBL0000944'), // 조치중
          data: [],
        },
        {
          id: 'complete',
          name: this.$comm.getLangLabel('LBL0000945'), // 개선완료(총합)
          data: [],
        },
        {
          id: 'overAction',
          name: this.$comm.getLangLabel('LBL0000934'), // 지연 건수
          data: [],
        },
      ]
      this.imprChart.chartOptions.tooltip.y.formatter = (val) => {
        return val + " " + thisVue.$comm.getLangLabel('LBL0000389') //" 건"
      }
    },
    getMapPriodParam() {
      /**
       * 초기 지도 정보를 불러올 때에는 기간 정보가 넘어가지 않게 처리
       */
      this.$http.url = this.mapObj.envUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$set(this.mapObj.taskParam, 'workPermitPeriod', [_result.data[0].start, _result.data[0].end])
        this.$set(this.mapObj.taskParam, 'assessRiskPeriod', [_result.data[1].start, _result.data[1].end])
        this.$set(this.mapObj.taskParam, 'mocPeriod', [_result.data[2].start, _result.data[2].end])
        this.$set(this.mapObj.taskParam, 'accidentPeriod', [_result.data[3].start, _result.data[3].end])
        this.$set(this.mapObj.taskParam, 'equipCheckPeriod', [_result.data[4].start, _result.data[4].end])
      },);
    },
    onClickProcess() {
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    getMapData() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      let defaultPlantCd = window.sessionStorage.getItem('defaultPlantCd');
      this.$http.url = this.mapObj.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: defaultPlantCd,
        workPermitPeriod: this.mapObj.taskParam.workPermitPeriod,
        assessRiskPeriod: this.mapObj.taskParam.assessRiskPeriod,
        mocPeriod: this.mapObj.taskParam.mocPeriod,
        accidentPeriod: this.mapObj.taskParam.accidentPeriod,
        equipCheckPeriod: this.mapObj.taskParam.equipCheckPeriod,
      }
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.mapObj.maps = _result.data;
          this.$_.forEach(this.mapObj.maps, map => {
            if (map.maps && map.maps.length > 0) {
              this.$_.forEach(map.maps, item => {
                this.$set(item, 'isHover', false)
              });
            }
            if (map.otherMaps && map.otherMaps.length > 0) {
              this.$_.forEach(map.otherMaps, item => {
                this.$set(item, 'isHover', false)
              });
            }
          })
          this.$refs['carousel'].style.setProperty("--map-ratio", this.mapObj.maps[0].ratio);
        }
      },);
    },
    intervalActionMap() {
      this.mapObj.interval.obj = setInterval(() => {
        this.mapObj.interval.seconds--
        
        if (this.mapObj.interval.seconds === 0) {
          if (this.mapObj.activeTask === 5) {
            this.mapObj.activeTask = 1
          } else {
            this.mapObj.activeTask++;
          }
          this.mapObj.interval.seconds = 10;
        }
      }, 1000);
    },
    pause() {
      this.mapObj.interval.isPause = !this.mapObj.interval.isPause
      if (this.mapObj.interval.isPause) {
        clearInterval(this.mapObj.interval.obj);
      } else {
        this.intervalActionMap()
      }
    },
    /* eslint-disable no-unused-vars */
    getWidth(item) {
      let width = 0;
      this.$_.forEach(item.resultTypes, resultType => {
        width += (resultType.sopWorkTypeName.length * 10) + 60
      })
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + (item.resultTypes.length * 40)
      let detailLocation = item.locationXcoordinate + 250
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 30;
      let calLocation = item.locationYcoordinate + (item.resultTypes.length * 76)
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return !item.isHover ? '30px' : top + 'px'
    },
    workPermitIconName(resultType) {
      let icon = '';
      switch(resultType.sopWorkTypeCd) {
        case 'SPT0000001': // 일반
          icon = 'engineering';
          break;
        case 'SPT0000005': // 화기
          icon = 'local_fire_department';
          break;
        case 'SSWT000001': // 밀폐공간
          icon = 'dangerous';
          break;
        case 'SSWT000005': // 정전
          icon = 'flash_off';
          break;
        case 'SSWT000010': // 방사선
          icon = 'warning';
          break;
        case 'SSWT000015': // 굴착
          icon = 'hardware';
          break;
        case 'SSWT000020': // 고소
          icon = 'stairs';
          break;
        case 'SSWT000025': // 중장비
          icon = 'agriculture';
          break;
      }
      return icon;
    },
    workPermitbadgeColor(swpStepName) {
      let icon = '';
      switch(swpStepName) {
        case '승인완료':
          icon = 'light-blue';
          break;
        case '연장':
          icon = 'amber';
          break;
        case '작업완료':
          icon = 'light-green';
          break;
      }
      return icon;
    },
    setStyleTaskArea(mp) {
      let style = {
        transform: 'translate(' + String(mp.x * this.mapRate) + 'px, ' + String(mp.y * this.mapRate + 30) + 'px)',
        'z-index': 1,
        'user-select': 'auto',
        width: '1px',
        height: '1px',
        position: 'absolute'
      }
      if (mp.isHover) {
        style['z-index'] = 7
      }
      return style;
    },
    imgHeight(map) {
      return String(map.ratio * 800) + 'px'
    },
    openWorkPermit(item) {
      if (!item) return;
      this.popupOptions.isFull = true;
      this.popupOptions.title = 'LBL0000938' //'작업허가서 상세';
      this.popupOptions.param = {
        sopWorkPermitId: item.sopWorkPermitId,
        permitTypeCd: item.permitTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    getWorkProgress() {
      this.$http.url = this.workProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd1
      }
      this.$http.request((_result) => {
        this.workProgressList = _result.data
      },);
    },
    getWorkPermits() {
      // this.$http.url = this.workPermitUrl;
      // this.$http.type = 'GET';
      // this.$http.param = {
      //   plantCd: this.plantCd2,
      // };
      // this.$http.request((_result) => {
      //   this.grid1.data = _result.data
      // },);
    },
    getImprProgress() {
      this.$http.url = this.imprProgressUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd3,
      };
      this.$http.request((_result) => {
        this.grid2.data = _result.data
      },);
    },
    getNearmiss() {
      this.$http.url = selectConfig.sop.iim.accident.near.list.url; //selectConfig.sop.iim.accident.near.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCdAcc,
        startYmd: '',
        endYmd: '',
      };
      this.$http.request((_result) => {
        this.gridAcc.data = _result.data
      },);
    },
    intervalTooltip1() {
      this.isToolTip1 = setInterval(() => {
        if (this.showTooltopCnt == 8) {
          this.showTooltopCnt = 0;
        }
        for(let c = 0; c < 8; c++) {
          this.showTooltop[c] = false;
        }
        this.showTooltop[this.showTooltopCnt] = true;
        this.showTooltopCnt++;
      }, 2000);
    },
    // getSafPatrols() {
    //   this.$http.url = this.patrolUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = {
    //     ibmTaskTypeCd: 'ITT0000065',
    //   },
    //   this.safRate = [];
    //   this.$http.request((_result) => {
    //     let taskIdx = 0;
    //     this.$_.forEach(_result.data, task => {
    //       this.$_.forEachRight(this.imprChart2.series, item => {
    //         if (!this.safRate[taskIdx] && task[item.id] > 0) {
    //           this.safRate[taskIdx] = task.requestTotal > 0 ?
    //             String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
    //         }
    //       })
    //       taskIdx++;
    //     })
    //     this.$_.forEach(this.imprChart2.series, item => {
    //       item.data = this.$_.map(_result.data, item.id)
    //     })
    //     let xaxis = this.$_.map(_result.data, 'plantName');
    //     let annotations = [];
    //     this.safRate.forEach((r, idx) => {
    //       // xaxis[idx] += (r ? '(' + r + ')' : '');
    //       annotations[idx] = {
    //         x: xaxis[idx],
    //         strokeDashArray: 0,
    //         borderColor: '#EEEEEE',
    //         borderWidth: 0,
    //         label: {
    //           borderColor: '#775DD0',
    //           orientation: 'horizontal',
    //           style: {
    //             color: '#fff',
    //             fontSize: '12px',
    //             background: '#775DD0',
    //           },
    //           text: r ? r : '',
    //           // text: r ? '이행률 : ' + r : '',
    //         }
    //       }
    //     })
    //     xaxis.forEach((x, idx) => {
    //       if (!annotations[idx]) {
    //         annotations[idx] = {
    //           x: x,
    //           strokeDashArray: 0,
    //           borderColor: '#EEEEEE',
    //           borderWidth: 0,
    //           // borderColor: '#775DD0',
    //           label: {
    //             borderColor: '#775DD0',
    //             orientation: 'horizontal',
    //             style: {
    //               color: '#fff',
    //               fontSize: '12px',
    //               background: '#775DD0',
    //             },
    //             text: '',
    //           }
    //         }
    //       }
    //     })
    //     this.imprChart2.chartOptions.xaxis.categories = xaxis;
    //     this.imprChart2.chartOptions.annotations.xaxis = annotations;
        
    //     this.imprChart2.chartWidth = '100%';
    //     this.$refs['imprChart2'].refresh();
    //   },);
    // },
    getYearList() {
      // this.$http.url = this.yearUrl;
      // this.$http.type = 'GET';
      // this.$http.param = this.searchParam;
      // this.$http.request((_result) => {
      //   this.yearOcuurChart.series = [];

      //   this.$_.forEach(_result.data, _item => {
      //   // 년도에 따른 idx를 구하여 series.data의 위치 통일
      //     let idx = this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear)
      //     if (this.$_.findIndex(this.yearOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
      //       this.yearOcuurChart.series.push({
      //         plantCd: _item.plantCd,
      //         name: _item.plantName,
      //         data: [0, 0, 0, 0, 0]
      //       })
      //     }
      //     this.yearOcuurChart.series[this.$_.findIndex(this.yearOcuurChart.series, 
      //     { plantCd: _item.plantCd })].data[idx] = _item.yearCount
      //   })
      //   this.setChart();
      // },);
    },
    setChart() {
      setTimeout(() => {
        this.yearOcuurChart.chartWidth = '90%';
      }, 200);
      this.$refs['yearOcuurChart'].refresh();
    },
    datachange4(props) {
      this.getImprStatus();
    },
    getImprStatus() {
      let dates = [];
      if (this.actionCompleteRequestDate !== null) {
        dates = [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31'];
      }
      this.$http.url = this.rateUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.plantCd4,
        actionCompleteRequestDates: dates,
      }
      this.rate = [];
      this.$http.request((_result) => {
        let taskIdx = 0;
        this.$_.forEach(_result.data, task => {
          this.$_.forEachRight(this.imprChart.series, item => {
            if (!this.rate[taskIdx] && task[item.id] > 0) {
              this.rate[taskIdx] = task.requestTotal > 0 ?
                String(this.$_.round((task.complete / task.requestTotal) * 100)) + '%' : '0%';
            }
          })
          this.ibmTaskTypeCdList.push({
            code: task.ibmTaskTypeCd,
            codeName: task.ibmTaskTypeName,
          });
          taskIdx++;
        })
        this.$_.forEach(this.imprChart.series, item => {
          item.data = this.$_.map(_result.data, item.id)
        })
        let xaxis = this.$_.map(_result.data, 'ibmTaskTypeName');
        let annotations = [];
        this.rate.forEach((r, idx) => {
          // xaxis[idx] += (r ? '(' + r + ')' : '');
          annotations[idx] = {
            x: xaxis[idx],
            strokeDashArray: 0,
            borderColor: '#EEEEEE',
            borderWidth: 0,
            label: {
              borderColor: '#775DD0',
              orientation: 'horizontal',
              style: {
                color: '#fff',
                fontSize: '12px',
                background: '#775DD0',
              },
              text: r ? r : '',
              // text: r ? '이행률 : ' + r : '',
            }
          }
        })
        xaxis.forEach((x, idx) => {
          if (!annotations[idx]) {
            annotations[idx] = {
              x: x,
              strokeDashArray: 0,
              borderColor: '#EEEEEE',
              borderWidth: 0,
              // borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                orientation: 'horizontal',
                style: {
                  color: '#fff',
                  fontSize: '12px',
                  background: '#775DD0',
                },
                text: '',
              }
            }
          }
        })
        this.imprChart.chartOptions.xaxis.categories = xaxis;
        this.imprChart.chartOptions.annotations.xaxis = annotations;
        
        this.imprChart.chartWidth = '100%';
        this.$refs['imprChart'].refresh();
      },);
    },
    linkClickAcc(row) {
      this.popupOptions.title = 'LBL0000946'; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/iim/near/nearAccidentDetail.vue'}`);
      this.popupOptions.width = "90%"
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClickImp(row) {
      this.popupOptions.title = 'LBL0000947'; // 개선 상세
      this.popupOptions.param = {
        sopImprovementId: row ? row.sopImprovementId : '',
        ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
      };
      this.popupOptions.width = "80%"
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    selectedBarImp(e, chart, opts) {
      this.popupOptions.width = '90%';
      this.popupOptions.title = "LBL0000948"; // 개선목록 (업무별 현황)
      this.popupOptions.param = {
        plantCd: this.plantCd4,
        ibmTaskTypeCd: this.$_.filter(this.ibmTaskTypeCdList, { codeName: this.imprChart.chartOptions.xaxis.categories[opts.dataPointIndex] })[0].code,
        actionCompleteRequestDates: [this.actionCompleteRequestDate + '-01-01', this.actionCompleteRequestDate + '-12-31']
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/impr.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
<style lang="sass">
.no-padding
  padding: 0px 0px 6px 0px !important
.maindashboard .cardcontents
  box-shadow: 0 !important
  border: 1px solid rgba(0,0,0,0.12) !important
  border-radius: 10px !important
  margin-top: 6px
  margin-bottom: 6px
  margin-right: 6px
  .card-body
    padding: 10px !important
    border-radius: 0 0 10px 10px !important
    background: #fff
  .card-header:before
    font-family: "Material Icons"
    content: "\e876"
  .card-header
    border-bottom-width: 0px !important
    border-radius: 10px 10px 0 0 !important
    height: 40px
    color: #0072c6
    background: #f8f8f9
    display: inline-block
    padding-top: 8px
    width: 100%
    .card-more
      float: right !important
      cursor: pointer
      color: #d2d2d2
    .card-more:hover
      color: #1ab394
.main-header-input
  margin-top: -5px
  padding-right: 5px
.main-header-input2
  margin-top: -5px
  padding-right: 5px
  width: 240px !important
  .customDate
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
.cardcontents .card-body.nopadgrid
  padding-top: 0px !important
.btn-fixed-width-height
  width: 95%
  margin: 3px !important
  pointer-events: none

.directorMainLayer
  .workPermit-carousel
    border-radius: 10px
    height: var(--map-height) !important
    .q-carousel__slide
      width: calc(var(--map-ratio) * var(--map-height)) !important
    .q-carousel--padding, .q-carousel__slide
      padding: 0 !important
    .q-carousel__arrow
      transition: all 0.5s
      opacity: 0.2
    .q-carousel__arrow:hover
      transition: all 0.5s
      opacity: 0.8
    .q-carousel__slides-container
      .q-panel.scroll
        overflow: hidden !important


.directorMainLayer
  .caption-work2
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #fff
    padding: 10px
  .caption-work
    font-size: 0.9em
    // font-style: italic
    white-space: normal
    color: #555
    margin-top: 4px

.directorMainLayer
  .mainmapstart
    float: right
    margin-right: 20px

.directorMainLayer
  .mainMarkImage
    padding: 0px !important
    text-align: center
    font-size: 20px
    height: 30px !important
    position: absolute
    padding: 16px
    color: #C10015
    z-index: 3 !important
    // background: rgba(0,0,0,0.47)
    .markImage-abbreviation-parent
      // width:240px
      text-align: start
      position: absolute
      .markImage-abbreviation
        cursor: pointer
        display: inline-block
        // min-width:60px
        // max-width:250px
        width: auto
        border-left: 1px solid #757575
        border-right: 1px solid #757575
        border-top: 1px solid #757575
        border-bottom: 1px solid #757575
        border-radius: 5px
        .markImage-tran
          border-radius: 5px
        .markImage-abbreviation-banner
          border-right: 1px solid #757575
          display: inline-block
          text-align: center
          min-width:60px
          padding: 1px !important
          border-radius: 5px 0px 0px 5px
          i
            font-size: 20px
          div
            font-size: 12px !important
        .markImage-abbreviation-banner.last-banner
          border-right: 0
          border-radius: 0px 5px 5px 0px
        .markImage-abbreviation-plus
          display: inline-block
          text-align: center
          min-width:40px
        .markImage-abbreviation-banner-detail
          border-radius: 5px
          .q-banner__avatar i
            font-size: 20px !important
          .q-banner__content
            font-size: 12px !important

.directorMainLayer
  .markImage-abbreviation > div
    -webkit-animation: fadeInFromNone 0.1s ease-out
    -moz-animation: fadeInFromNone 0.1s ease-out
    -o-animation: fadeInFromNone 0.1s ease-out
    animation: fadeInFromNone 0.1s ease-out


.main-map-card 
  border-radius: 10px !important
  border-width: 0px !important
  background-color: #161d31 !important
  .q-tab--inactive
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
  .q-tab--active
    border: 1px solid #fff
    margin: 0px 2px !important
    border-radius: 5px !important
    background-color: #fff !important
    color:  #161d31 !important
  .q-tab__indicator
    display: none !important
  .customCardbody
    margin: 0px !important
    .main-card-head-text
      font-weight: 600
      font-size: 0.9rem
      line-height: 26px
  .cardhead
    height: 41px !important
    background-color: #343d55 !important
    color: #fff !important
    padding-top: 5px !important
    font-size: 1.1em !important
  .customCardbody
    background-color: #283046 !important
    border-radius: 0 0 10px 10px !important
    padding-top: 10px !important
  .q-separator--horizontal
    display: none !important
  .mainDashboardTab
    .q-tab-panel
      display: table
      width: 100%
    .q-tab__label
      font-size: 1.0em !important
      font-weight: 600
  .mainDashboardTabS
    .q-tab__label
      font-size: 1.1em !important
  .maintabTable
    width: 100%
    display: inline-flex
    color: #fff !important
  .maintabTableTop
    border-top: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintabTd
    font-size: 1.1em !important
    padding: 8px 5px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    width: 100%
  .maintabTr
    font-size: 1.1em !important
    padding: 8px 5px
    width: 100%
    background-color: #161d31 !important
  .maintabTd7
    width: 70%
  .maintabTd6
    width: 60%
  .maintabTd5
    width: 50%
  .maintabTd4
    width: 40%
  .maintabTd3
    width: 30%
    text-align: right
  .maintabTd2
    width: 20%
  .maintabTd1
    width: 10%
  .maintabTdgreen
    color: #28c76f !important
    font-size: 1.05em !important
    text-align: center
  .maintablebody
    height: 250px
    background-color: #343d55 !important
    border-radius: 0 0 10px 10px !important
    overflow-y: auto
    border-bottom: 1px solid rgb(255,255,255, 0.3)
    border-left: 1px solid rgb(255,255,255, 0.3)
    border-right: 1px solid rgb(255,255,255, 0.3)
  .maintablebody2
    height: 300px !important
</style>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
.my-class {
  /* background-color: #9E9E9E; */
  border: 2px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(40, 48, 70, 0.2);
  border-radius: 10px;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  position: absolute;
  text-align: center;
  color: black;
  cursor: pointer;
  /* z-index: 1 !important; */
}
.my-class div {
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  background-color: rgba(40, 48, 70, 0.6);
}
.my-active-class {
  /* opacity: 0.5; */
  background-color: rgba(115,103,240,0.5);
  /* color:azure; */
}
.chip-task {
  margin-left: 8px !important;
}
.app-main__inner .task-detail {
  z-index: 5;
  width:250px;
  position: relative;
}
.app-main__inner .task-detail .q-banner {
  background-color: #283046;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 0px !important;
  color: #fff !important;
}
.app-main__inner .task-detail .q-banner .col-auto {
  padding: 0px !important;
}
.mainmapbannerTitle {
  width: 100%;
  color: #fff;
  background-color: #343d55;
  padding: 10px !important;
  border-radius: 3px;
  font-size: 0.9em !important;
}
.open-task {
  left: 100%; 
  width: 80%;
  transform: translate(-100%, 0);
  position: absolute;
  z-index: 1000;
  padding: 10px !important;
  border-radius: 15px !important;
  background: #fff;
}
.maintablebody::-webkit-scrollbar {
  width: 10px;
}
.maintablebody::-webkit-scrollbar-track {
  background: #343d55 !important;
  border-radius: 0 0 10px 0;
}

.maintablebody::-webkit-scrollbar-thumb {
  background-color: #161d31 !important;
  background-image: linear-gradient(315deg, #161d31 0%, #161d31 74%);
}
.link-line {
  text-decoration: underline;
  cursor: pointer;
}
.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #5e5f61 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 9999;
  font-size: 12px;
}
</style>